<template>
  <div id="news-login" class="container-fluid">
    <div class="row mx-0 align-items-center justify-content-center">
      <div class="card justify-content-center shadow-lg py-3">
        <h2 class="my-4 text-center">Portal News</h2>
        <label for="login"><strong>Usuário:</strong></label>
        <input @keypress.enter="sendLogin" type="text" v-model="login" class="form-control" />
        <label for="password"><strong>Senha:</strong></label>
        <input @keypress.enter="sendLogin" type="password" v-model="pass" class="form-control" />
        <button @click="sendLogin" :disabled="loading" class="btn btn-success my-4 mx-auto w-50">
          Entrar
          <b-icon v-if="!loading" icon="box-arrow-in-right" />
          <b-icon v-else icon="arrow-clockwise" animation="spin" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        login: '',
        pass: ''
      }
    },
    beforeMount() {
      this.$store.commit('GET_AUTH');
      if (this.$store.state.news.login.token) this.$router.push('/noticias/inicio')
    },
    methods: {
      sendLogin() {
        if (!this.login || !this.pass)
          return this.swal(false, 'Preencha os dados corretamente!')

        this.loading = true
        const encode = `${this.pass.split('').reverse().join('')}:${this.login}`;
        this.$store.dispatch('logIn', Buffer.from(`${encode}`, 'utf8').toString('base64'))
          .then(() => this.$router.push('/noticias/inicio'))
          .catch((error) => [this.swal(false, error.data.mensagem, error)])
          .finally(() => this.loading = false)
      }
    }
  }
</script>

<style scoped>
  [id*='news-'] {
    background: #000428;
    background: -webkit-linear-gradient(to bottom, #004e92, #000428);
    background: linear-gradient(to bottom, #004e92, #000428);
  }

  .row {
    height: 100vh;
  }

  .card {
    min-height: 300px;
    max-width: 300px;
  }
</style>